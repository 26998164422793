export const store = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9004`,
  },
  dev: {
    api: "",
    ip: "http://sto-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://sto-test.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stagestore.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://store.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://authority-sto.hb.com",
  },
};
