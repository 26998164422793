<template>
  <hb-dialog
    title="评审记录"
    ref="dialog"
    width="1000px"
    :destroy-on-close="true"
    :loading="false"
    :showFooter="false"
    :modal-append-to-body="true"
    :append-to-body="true"
    :before-close="beforeClose"
  >
    <div class="approval-dialog-content" slot="normal">
      <div class="approval-dialog-add">
        <el-button type="primary" size="mini" icon="icon-main-tianjia" @click="add">新增</el-button>
      </div>
      <div class="approval-content">
        <div class="approval-item" v-for="(item, index) in approvalList" :key="index">
          <div class="approval-item-left">
            <div class="approval-item-left-form">
              <el-form
                :ref="'form' + index"
                :model="item"
                :rules="rules"
                label-width="90px"
                :disabled="!item.isEdit"
              >
                <el-row :gutter="20" style="display: flex; flex-wrap: wrap">
                  <el-col :span="8">
                    <el-form-item label="评审时间" prop="reviewTime">
                      <el-date-picker
                        size="mini"
                        v-model="item.reviewTime"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="评审人" prop="reviewerId">
                      <el-select
                        size="mini"
                        placeholder="请选择评审人"
                        v-model="item.reviewerId"
                        multiple
                        collapse-tags
                        filterable
                      >
                        <el-option
                          v-for="item in usersList"
                          :key="item.userId"
                          :label="item.name"
                          :value="item.userId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="评审结果" prop="reviewResult">
                      <el-select
                        size="mini"
                        placeholder="请选择评审结果"
                        v-model="item.reviewResult"
                      >
                        <el-option
                          v-for="item in resultList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="评审说明" prop="reviewDescription">
                      <el-input
                        placeholder="针对机会、风险、资源占用等方面说明"
                        type="textarea"
                        size="medium"
                        maxlength="2000"
                        v-model="item.reviewDescription"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="approval-item-bottom">
              <div class="approval-item-left-desc">
                <span class="font-12px">更新时间：{{ item.modifyTime }}</span>
                <span class="font-12px" style="margin-left: 30px">
                  更新人：{{ item.modifyName }}
                </span>
              </div>
              <div class="approval-item-right">
                <span @click="edit(item, index)">
                  {{ item.isEdit ? "完成" : "编辑" }}
                </span>
                <span style="margin-left: 10px" @click="del(index)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hb-dialog>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
let initForm = {
  isEdit: true, // 记录当前这条评审是不是正在编辑
  reviewResult: undefined,
  reviewDescription: "",
};
export default {
  name: "ApprovalDialog",
  computed: {
    usersList() {
      return this.$store.state.usersList;
    },
  },
  data() {
    return {
      aboutId: "",
      approvalList: [],
      rules: {
        reviewTime: [{ required: true, message: "请选择评审时间" }],
        reviewerId: [{ required: true, message: "请选择评审人" }],
        reviewResult: [{ required: true, message: "请选择评审结果" }],
        reviewDescription: [{ required: true, message: "请输入评审说明" }],
      },
      resultList: [
        {
          label: "通过",
          value: true,
        },
        {
          label: "不通过",
          value: false,
        },
      ],
      apiPrefix: "",
      baseURL: "/platform",
    };
  },
  methods: {
    open(data) {
      console.log(data, "传入");
      this.aboutId = data.aboutId;
      this.apiPrefix = data.apiPrefix;
      this.baseURL = data.baseURL || "/platform";
      this.getData();
      this.$refs.dialog.open();
    },
    getData() {
      this.$http
        .get(`${this.apiPrefix}/review/list`, {
          params: { aboutId: this.aboutId },
          baseURL: this.baseURL,
        })
        .then((res) => {
          this.approvalList = res?.map((i) => ({ ...i, isEdit: false })) || [];
        });
    },
    add() {
      this.approvalList.push({
        modifyTime: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifyName: this.$store.state.userInfo.name,
        reviewTime: moment().format("yyyy-MM-DD HH:mm:ss"),
        reviewerId: [this.$store.state.userInfo.userId],
        ...cloneDeep(initForm),
      });
    },
    del(index) {
      this.$confirm("请确认是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let id = this.approvalList[index].id;
          if (id) {
            this.$http
              .delete(`${this.apiPrefix}/review/delete/${id}`, {
                baseURL: this.baseURL,
              })
              .then((res) => {
                this.$message.success("删除成功");
                this.approvalList.splice(index, 1);
              });
          } else {
            this.approvalList.splice(index, 1);
          }
        })
        .catch(() => {});
    },
    edit(row, index) {
      // 编辑完成时
      if (row.isEdit) {
        this.$refs["form" + index][0].validate((valid) => {
          if (valid) {
            const param = { ...row, aboutId: this.aboutId };
            this.$http
              .post(`${this.apiPrefix}/review/saveOrUpdate`, param, { baseURL: this.baseURL })
              .then((res) => {
                this.getData();
                this.$message.success("保存成功");
                row.isEdit = !row.isEdit;
              });
          } else {
            return false;
          }
        });
      } else {
        row.isEdit = !row.isEdit;
      }
    },
    // dialog点右上角按钮默认会直接关闭，这里让它先走校验
    beforeClose() {
      let flag = this.approvalList.some((i) => i.isEdit);
      if (flag) {
        this.$confirm("您有未保存的操作，确认返回？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.initData();
            this.$refs.dialog.close();
          })
          .catch(() => {});
      } else {
        this.initData();
        this.$refs.dialog.close();
      }
    },
    initData() {
      this.approvalList = [];
      this.aboutId = "";
      this.apiPrefix = "";
      this.baseURL = "";
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-date-editor {
  width: 172px;
}
.approval-dialog-add {
  height: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.approval-content {
  max-height: 550px;
  overflow: auto;

  .approval-item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    > div {
      height: 100%;
    }
    .approval-item-bottom {
      display: flex;
      justify-content: space-between;
    }
    .approval-item-left {
      .approval-item-left-form {
        border: 1px solid gainsboro;
        padding: 5px 20px 5px 5px;
        margin-bottom: 10px;

        .el-form-item {
          margin-bottom: 20px;
        }
      }
    }
    .approval-item-right {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        cursor: pointer;
        color: #488af8;
      }
    }
  }
  .font-12px {
    font-size: 12px;
    color: rgb(165, 165, 165);
  }
}
</style>