export const research = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9006`,
  },
  dev: {
    api: "",
    ip: "http://research-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://research.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stgresearch.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://research.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://research.hb.com",
  },
};
