export const market = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9002`,
  },
  dev: {
    api: "",
    ip: "http://mar-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://mar-test.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stagemarket.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://market.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://authority-mar.hb.com",
  },
};
