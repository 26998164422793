export const produce = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9003`,
  },
  dev: {
    api: "",
    ip: "http://pro-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://pro-test.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stageproduce.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://produce.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://authority-pro.hb.com",
  },
};
