<template>
  <div>
    <hb-dialog @closed="clear" ref="dialog" title="查看草稿" width="800px">
      <div slot="normal">
        <div class="loading el-icon-loading" v-if="loading"></div>
        <div class="draft-list" v-else>
          <div class="draft-item mb15" v-for="(item, index) in list">
            <div class="flex flex-between">
              <div class="draft-title">{{ item.title }}</div>
              <div class="btn-group">
                <span class="draft-date">{{ item.createTime }}</span>
                <span class="main-color pointer ml15 mr10" @click="edit(item)">编辑</span>
                <span class="main-color pointer" @click="remove(item.id)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="dialogFooter">
        <el-button plain type="primary" @click="close">关闭</el-button>
      </div>
    </hb-dialog>

    <hb-dialog title="保存草稿" @submit="saveDraft" width="520px" ref="saveDialog">
      <div slot="normal">
        <p class="mb10">草稿名称</p>
        <el-input v-model="saveParams.title" maxlength="40"></el-input>
      </div>
    </hb-dialog>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      title: "",
      list: [],
      loading: false,
      params: {
        sysCode: "",
        sourceType: "",
      },
      saveParams: {
        title: "",
        draftJson: "",
        sysCode: "",
        sourceType: "",
        id: "",
      },
    };
  },
  methods: {
    open(sysCode, sourceType) {
      this.$refs.dialog.open();
      this.loading = true;
      this.params.sysCode = sysCode;
      this.params.sourceType = sourceType;
      this.$http
        .get("/userDraft/getUserDraftList", {
          baseURL: "/platform",
          params: this.params,
        })
        .then((res) => {
          this.list = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$refs.dialog.close();
    },
    openTitle(sysCode, sourceType, id, data) {
      this.$refs.saveDialog.open();
      this.saveParams.title = moment().format("MM-DD HH:mm:ss 星期E");
      this.saveParams.sysCode = sysCode;
      this.saveParams.sourceType = sourceType;
      this.saveParams.draftJson = JSON.stringify(data);
      this.saveParams.id = id;
    },
    saveDraft() {
      this.$http
        .post("/userDraft/saveOrUpdateUserDraft", this.saveParams, { baseURL: "/platform" })
        .then((res) => {
          this.$message.success("操作成功");
          this.$refs.saveDialog.close();
        })
        .finally(() => {
          this.$refs.saveDialog.resetLoading();
        });
    },
    edit(item) {
      try {
        let data = JSON.parse(item.draftJson);
        window.dispatchEvent(
          new CustomEvent("editDraft", {
            bubbles: false,
            detail: {
              data,
              id: item.id,
            },
          }),
        );
        this.$refs.dialog.close();
      } catch (error) {
        this.$message.error("草稿解析异常");
      }
    },
    remove(id) {
      this.$confirm("请确认是否删除该草稿", "提示")
        .then(() => {
          this.$http
            .get("/userDraft/removeUserDraft?id=" + id, {
              baseURL: "/platform",
            })
            .then(() => {
              this.list = this.list.filter((i) => i.id != id);
            });
        })
        .catch(() => {});
    },
    clear() {
      this.list = [];
      this.params = {
        sysCode: "",
        sourceType: "",
      };
    },
  },
};
</script>