<template>
  <svg class="svg-icon" :class="className" aria-hidden="true" :style="{ fontSize }">
    <use :xlink:href="iconClass" />
  </svg>
</template>

<script>
export default {
  name: "GSVG",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "inherit",
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
