export const quality = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9005`,
  },
  dev: {
    api: "",
    ip: "http://qms-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://qms-test.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stageqms.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://qms.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://authority-qms.hb.com",
  },
};
