import Store from "@/store";
import * as BASES from "@/config/qiankun/base";
import * as Servers from "@/config/microServers";
import { mainMethods } from "@/assets/js/globalUtils";
let microApps = Object.keys(BASES);
let microAppsConfig = microApps.map((app) => {
  return {
    name: app,
    container: "#microAppContainer",
    activeRule: BASES[app],
    entry: Servers[app][process.env.VUE_APP_ENV].ip,
    props: {
      Store,
      mainMethods,
      api: { market: process.env.VUE_APP_MARKET },
    },
  };
});
export default microAppsConfig;
