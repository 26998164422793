<template>
  <hb-dialog
    :modal-append-to-body="true"
    :append-to-body="true"
    @submit="submit"
    @closed="clear"
    ref="dialog"
    title="修改按灯"
    width="320px"
  >
    <div slot="normal">
      <el-form label-position="top">
        <el-form-item
          label="按灯"
          :rules="{
            required: true,
            message: '必填',
          }"
        >
          <el-select v-model="formData.lightValue">
            <el-option v-for="item in lightOpt" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="按灯说明">
          <el-input
            v-model="formData.lightExplainValue"
            type="textarea"
            :autosize="{ minRows: 1 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </hb-dialog>
</template>
<script>
import loadsh from "lodash";
export default {
  data() {
    return {
      formData: {
        systemName: "saas_platform",
        tableName: "",
        id: "",
        lightKey: "light",
        lightValue: "",
        lightExplainKey: "light_explain",
        lightExplainValue: "",
        modifyTimeKey: "modify_time",
        modifyIdKey: "modify_id",
        modifyNameKey: "modify_name",
      },
      lightOpt: ["红灯", "黄灯", "绿灯"],
      row: {},
    };
  },
  methods: {
    open(data) {
      this.$refs.dialog.open();
      Object.assign(this.formData, data.formData);
      this.formData.id = data.row.id;
      this.formData.lightValue = data.row[this.formData.lightKey];
      this.formData.lightExplainValue = data.row[this.formData.lightExplainKey];
      this.row = data.row;
    },
    submit() {
      let pramas = loadsh.cloneDeep(this.formData);
      for (let attr in pramas) {
        attr != "id" &&
          (pramas[attr] = pramas[attr].replace(/[A-Z]/g, ($) => "_" + $.toLowerCase())); // 商机的id有大写字母要规避
      }
      this.$http
        .post("/light/commonUpdateLight", pramas)
        .then(() => {
          this.$message.success("操作成功");
          this.row[this.formData.lightKey] = this.formData.lightValue;
          this.row[this.formData.lightExplainKey] = this.formData.lightExplainValue;
          this.$refs.dialog.close();
        })
        .finally(() => {
          this.$refs.dialog.resetLoading();
        });
    },
    clear() {
      this.formData = {
        systemName: "saas_platform",
        tableName: "",
        id: "",
        lightKey: "light",
        lightValue: "",
        lightExplainKey: "light_explain",
        lightExplainValue: "",
        modifyTimeKey: "modify_time",
        modifyIdKey: "modify_id",
        modifyNameKey: "modify_name",
      };
      this.row = {};
    },
  },
};
</script>