<template>
  <div class="netdisc-uploader" :class="[smalltype ? 'small' : '']" v-if="visible">
    <!-- <el-button @click="print">log</el-button> -->
    <div v-show="!smalltype" class="body">
      <div class="hide" title="最小化" @click="smalltype = true">
        <span class="min"></span>
      </div>
      <el-tabs v-model="uploadStatus">
        <el-tab-pane :label="`全部(${allUploadLines.length})`" name="all"></el-tab-pane>
        <el-tab-pane
          :label="`上传中(${countNumberByStatus('padding')})`"
          name="padding"
        ></el-tab-pane>
        <el-tab-pane :label="`已暂停(${countNumberByStatus('pause')})`" name="pause"></el-tab-pane>
        <el-tab-pane
          :label="`排队中(${countNumberByStatus('inline')})`"
          name="inline"
        ></el-tab-pane>
        <el-tab-pane
          :label="`上传成功(${countNumberByStatus('success')})`"
          name="success"
        ></el-tab-pane>
        <el-tab-pane
          :label="`上传失败(${countNumberByStatus('failed')})`"
          name="failed"
        ></el-tab-pane>
        <el-tab-pane
          :label="`传输成功(${countNumberByStatus('transmitSuccess')})`"
          name="transmitSuccess"
        ></el-tab-pane>
        <el-tab-pane
          :label="`已取消(${countNumberByStatus('cancel')})`"
          name="cancel"
        ></el-tab-pane>
      </el-tabs>
      <div class="tip flex-row-between">
        <div>
          总速度：{{ countSpeedUnit(countTotalSpeed) }} 总文件数：{{ allUploadLines.length }}个
          <span @click="print">最多支持{{ maxUploadLines }}个文件同时上传</span>
        </div>
        <div class="tab-view" @click="singpelView = !singpelView">
          {{ singpelView ? "文件视图" : "任务视图" }}
        </div>
      </div>
      <ul class="uploader-wrapper">
        <template v-for="batch in netdisc">
          <li
            class="batch-wrapper"
            :key="batch.uid"
            v-show="
              uploadStatus === 'all' || batch.lines.some(({ status }) => status === uploadStatus)
            "
          >
            <div class="flex-row-between">
              <div class="title" :title="batch.path.join('>')">
                网盘路径：{{ batch.path.join(">") }}
              </div>
              <div class="tool flex-row-end">
                <span
                  title="优先上传本任务下的文件，可能会导致其它任务下的文件暂停上传"
                  v-show="
                    uploadStatus === 'all' && !batch.done && batch.lines.length <= maxUploadLines
                  "
                  :class="[
                    'btn',
                    batch.lines.some(({ status }) => status === 'inline' || status === 'pause')
                      ? ''
                      : 'not-allowed',
                  ]"
                  @click="preferredUpload(batch)"
                >
                  优先上传
                </span>
                <span
                  title="任务已结束，关闭任务栏"
                  v-if="batch.done"
                  @click="removeBatch(batch.uid)"
                >
                  <i class="el-icon-close"></i>
                </span>
                <span class="reamainder" v-else>剩余时间：{{ remainderTimeOfBatch(batch) }}</span>
              </div>
            </div>

            <ul>
              <template v-for="line in batch.lines">
                <li
                  :key="line.id"
                  class="line-wrapper"
                  v-show="(uploadStatus === 'all' || uploadStatus === line.status) && !singpelView"
                >
                  <div>
                    <div class="flex-row-between">
                      <GSVG
                        :iconClass="countIcon(line.file.name)"
                        className="custom-msg-icon"
                        fontSize="36px"
                      />
                      <div class="file-info">
                        <div>
                          <p class="filename">
                            {{ line.file.name }}
                          </p>
                          <div class="flex-row-between">
                            <p class="path">
                              路径：{{
                                line.file.webkitRelativePath || line.file.customWebkitRelativePath
                              }}
                            </p>
                            <p
                              class="action flex-row-between"
                              v-if="
                                line.status === 'padding' ||
                                line.status === 'inline' ||
                                line.status === 'pause'
                              "
                            >
                              <span
                                @click="start(line)"
                                v-if="line.status === 'pause' || line.status === 'inline'"
                              >
                                开始
                              </span>
                              <span @click="pause(line)" v-if="line.status === 'padding'">
                                暂停
                              </span>
                              <span @click="cancel(line)">取消上传</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="process-line">
                      <div class="down"></div>
                      <div
                        class="up"
                        :class="[line.status]"
                        :style="{ width: line.progress * 100 + '%' }"
                      ></div>
                    </div>
                    <div class="progress-info flex-row-between">
                      <div>
                        <span v-if="line.status === 'transmitSuccess' && !line.parent.folder">
                          传输成功，正在保存
                        </span>
                        <span v-else>{{ lineStatus(line.status) }}</span>
                      </div>
                      <div>
                        <span v-show="line.status === 'padding' || line.status === 'pause'">
                          进度：{{ Number(line.progress * 100).toFixed(2) + "%" }} |
                          {{ countSize(line.file.size, line.progress) }}
                        </span>
                      </div>
                      <div class="time">
                        <span v-if="line.status === 'padding'">
                          <span class="speed">速度：{{ countSpeedUnit(line.speed) }}</span>
                          <span>剩余时间：{{ countTimeUnit(line.remainderTime) }}</span>
                        </span>
                        <span v-if="line.status === 'transmitSuccess' || line.status === 'success'">
                          耗时：{{ countTimeUnit(line.transmitTotalTime / 1000) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
    <div class="small-box" @click="smalltype = false" v-show="smalltype">
      <i @click="visible = false" class="el-icon-close"></i>
      <span class="total-speed">{{ countSpeedUnit(countTotalSpeed) }}</span>
    </div>

    <hb-dialog
      append-to-body
      title="保存数据到浏览器"
      ref="dialog"
      @submit="saveDataInBrowser"
      width="700px"
    >
      <template slot="normal">
        <div style="line-height: 2">
          <div style="margin-bottom: 20px; font-weight: bold">
            文件成功上传至阿里云服务器，但数据保存到saas服务器失败，为避免重新上传请按照以下指引完成操作！
          </div>
          <div>
            1.输入一个标题如（2023年09月13日15时26分，文件夹A保存失败数据记录）作为此次失败记录的辨识。
          </div>
          <el-input v-model="failedTitle"></el-input>

          <div style="margin-top: 10px">
            2.点击确定将此次数据保存在浏览器中（清除浏览器缓存将丢失该数据！）。
          </div>
          <div style="margin-top: 10px">
            3.待saas服务器恢复后请尽快前往网盘>提交失败数据，完成操作。
          </div>
        </div>
      </template>
    </hb-dialog>
  </div>
</template>
<script>
import moment from "moment";
import OSS from "ali-oss";
import GSVG from "./GSVG.vue";
import lodash from "lodash";
let ossFolder = process.env.NODE_ENV === "production" ? "file" : "test";
export default {
  data() {
    return {
      visible: false,
      smalltype: false,
      netdisc: [],
      maxUploadLines: 10,
      uploadStatus: "all",
      singpelView: false, //视图模式
      ossFolder: ossFolder,
      failedTitle: "",
      failedData: "",
      fileMap: [
        {
          suffix: [".jpg", ".jpeg", ".png", ".gif"],
          icon: "#icon-color-JPG",
        },
        {
          suffix: [".doc", ".docx"],
          icon: "#icon-color-wendang",
        },
        {
          suffix: [".xls", ".xlsx"],
          icon: "#icon-color-excel",
        },
        {
          suffix: ".pdf",
          icon: "#icon-color-pdf",
        },
        {
          suffix: ".txt",
          icon: "#icon-color-txt",
        },
      ],
    };
  },
  methods: {
    //扫描上传队列，有空闲线程则开启上传
    async scanNetdisc(uploadLine) {
      if (this.fullLoaded) {
        uploadLine && (uploadLine.status = "inline");
        return;
      } else {
        let line = uploadLine || this.firstUploadLine;
        if (line) {
          //如果是从暂停恢复上传，则启用断点续传,用之前的oss对象，否则是新开启的线程，创建oss对象
          if (line.abortCheckPoint) {
            line.options.checkpoint = line.abortCheckPoint;
          } else {
            line.oss = new OSS({
              ...line.parent.token,
              refreshSTSToken: async () => {
                const info = await this.getSTSToken();
                return {
                  accessKeyId: info.accessKeyId,
                  accessKeySecret: info.accessKeySecret,
                  stsToken: info.stsToken,
                };
              },
              refreshSTSTokenInterval: 1000 * 60 * 30,
            });
          }
          line.options.progress = (p, cpt, res) => {
            line.progress = p;
            line.abortCheckPoint = cpt;
            line.startTime = line.endTime;
            line.endTime = new Date().getTime();
            if (line.endTime - line.startTime > 0 && cpt) {
              line.speed = ((cpt.partSize / (line.endTime - line.startTime)) * 1000) / 1024;
              line.remainderTime = (cpt.fileSize * (1 - p)) / 1024 / line.speed;
            }
          };
          line.forbidScan = false; //开启线程后需要重置forbidScan
          line.startTime = line.endTime = line.transmitStartTime = new Date().getTime();
          line.status = "padding";
          !uploadLine && this.scanNetdisc(); //手动开启线程时不能再进行扫描（优先下载功能限制）
          try {
            await line.oss.multipartUpload(line.file.path, line.file, line.options);
            line.status = "transmitSuccess";
            line.remainderTime = 0;
            line.speed = 0;
            line.transmitPauseTime = new Date().getTime();
            line.transmitTotalTime += line.transmitPauseTime - line.transmitStartTime;
            this.scanNetdisc();
            this.checkBatchIsDone(line);
          } catch (error) {
            line.speed = 0;
            if (error.name === "cancel") {
              if (line.status === "cancel") {
                //用户取消
                line.remainderTime = 0;
                line.progress = 1;
                this.checkBatchIsDone(line);
                line.transmitTotalTime = line.transmitPauseTime = line.transmitStartTime = 0;
              } else {
                //用户暂停
                line.transmitPauseTime = new Date().getTime();
                line.transmitTotalTime += line.transmitPauseTime - line.transmitStartTime;
              }
              !line.forbidScan && this.scanNetdisc();
            } else {
              //上传失败后开启断点续传
              if (line.reUpload < 5) {
                line.reUpload += 1;
                this.scanNetdisc(line);
                return;
              }
              //上传失败
              this.$message.error(error);
              line.status = "failed";
              line.progress = 1;
              line.remainderTime = 0;
              this.checkBatchIsDone(line);
              line.transmitTotalTime = line.transmitPauseTime = line.transmitStartTime = 0;
              !line.forbidScan && this.scanNetdisc();
            }
          }
        }
      }
    },
    submit({ folderId, folder, lines, paths, uid }) {
      //处理数据
      let params = {
        uid: uid,
        pId: folderId,
        netFileVos: [],
        t: !folder,
        paths,
      };
      lines.forEach((line) => {
        if (line.status === "transmitSuccess") {
          params.netFileVos.push({
            fileName: folder
              ? line.file.webkitRelativePath || line.file.customWebkitRelativePath
              : line.file.name,
            fileSize: line.file.size,
            fileUrl: line.file.path,
          });
        }
      });
      request(this);
      function request(_this) {
        _this.$http
          .post("/ndRecord/addFileNew", params)
          .then((res) => {
            window.dispatchEvent(new CustomEvent("refrashNetdisc"));
            lines.forEach((line) => {
              line.status === "transmitSuccess" && (line.status = "success");
            });
            if (res === "重复上传") return;
            _this.$message.success(`网盘路径：${lines[0].parent.path.join("/")},保存成功`);
          })
          .catch((error) => {
            _this
              .$confirm(
                `网盘路径：${lines[0].parent.path.join("/")},保存失败，是否重新提交`,
                "挽救措施",
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "重新提交",
                  cancelButtonText: "保存数据到浏览器",
                },
              )
              .then(() => {
                request(_this);
              })
              .catch((action) => {
                action === "cancel" && _this.open(params);
                lines.forEach((line) => {
                  line.status === "transmitSuccess" && (line.status = "savefailed");
                });
              });
          });
      }
    },
    checkBatchIsDone(line) {
      const parent = line.parent;
      if (!parent.folder) {
        //文件时一个个上传的，网络慢的时候有的文件传完了，所以要加sucess
        let res = parent.lines.every(({ status }) =>
          ["failed", "transmitSuccess", "cancel", "savefailed", "success"].includes(status),
        );

        if (res) {
          line.parent.done = true;
        }
        //文件得话直接保存数据到服务器
        this.submit({
          uid: line.id,
          folderId: parent.folderId,
          folder: parent.folder,
          lines: [line],
          paths: parent.emptyFolderPaths,
        });
      } else {
        //计算该批次的line是不是都完成了
        let res = parent.lines.every(({ status }) =>
          ["failed", "transmitSuccess", "cancel"].includes(status),
        );
        //至少要有一条数据传输成功
        let res1 = parent.lines.some((line) => line.status === "transmitSuccess");
        res && (line.parent.done = true);
        if (res && res1) {
          this.submit({
            uid: parent.uid,
            folderId: parent.folderId,
            folder: parent.folder,
            lines: parent.lines,
            paths: parent.emptyFolderPaths,
          });
        }
      }
    },
    cancel(line) {
      //从pause到cancel不会触发oss的catch,因为pause的时候已经调过oss.cancel了，需要手动走cancel的逻辑
      if (line.status === "pause") {
        line.speed = 0;
        line.remainderTime = 0;
        line.progress = 1;
        line.transmitTotalTime = line.transmitPauseTime = line.transmitStartTime = 0;
        line.status = "cancel";
        this.checkBatchIsDone(line);
        this.scanNetdisc();
      } else {
        line.status = "cancel";
        line.oss?.cancel(); //排队中的文件没有oss对象
      }
    },
    start(line) {
      if (!line.allowAction) return;
      line.allowAction = false;
      this.scanNetdisc(line);
      setTimeout(() => {
        line.allowAction = true;
      }, 1000);
    },
    preferredUpload({ lines, uid }) {
      try {
        //优先上传的线程数 = 需要关闭的其它线程数
        let linesNum = lines.filter(
          ({ status }) => status === "inline" || status === "pause",
        ).length;
        if (!linesNum) return;
        //优先的线程数不能大于maxUploadLines
        linesNum = Math.min(this.maxUploadLines, linesNum);
        //先关闭对应条数的线程
        let cancelNum = 0; //统计该操作已经关闭的线程数
        for (let i = 0; i < this.allUploadLines.length; i++) {
          if (cancelNum === linesNum) break;
          if (this.allUploadLines[i].pid !== uid && this.allUploadLines[i].status === "padding") {
            this.pause(this.allUploadLines[i], true);
            cancelNum++;
          }
        }
        //开启优先上传的线程数
        let scanNum = 0;
        for (let i = 0; i < lines.length; i++) {
          if (scanNum === linesNum) break;
          if (lines[i].status === "pause" || lines[i].status === "inline") {
            this.scanNetdisc(lines[i]);
            scanNum++;
          }
        }
      } catch (error) {
        this.$message.error("优先上传功能出错：" + error);
      }
    },
    pause(line, forbidScan = false) {
      if (!line.allowAction) return;
      line.allowAction = false;
      line.status = "pause";
      line.forbidScan = forbidScan;
      line.oss.cancel();
      setTimeout(() => {
        line.allowAction = true;
      }, 1000);
    },
    //总列队插入上传批次数据
    addBatch(batch) {
      this.netdisc.unshift(batch);
      this.scanNetdisc();
    },
    removeBatch(uid) {
      let batchIndex = this.netdisc.findIndex((batch) => batch.uid === uid);
      batchIndex != -1 && this.netdisc.splice(batchIndex, 1);
    },
    removeLine({ parent, id }) {
      //只剩一条line,则直接删除batch
      if (parent.lines.length === 1) {
        this.removeBatch(parent.uid);
        return;
      }
      let lineIndex = parent.lines.findIndex((line) => line.id === id);
      lineIndex != -1 && parent.lines.splice(lineIndex, 1);
    },
    upload({ type, folderId, path, fileList, token, emptyFolderPaths }) {
      let uid = new Date().getTime();

      if (type === "file") {
        //文件上传，插入到已存在的批次中
        let batch = this.netdisc.find((item) => item.folderId === folderId && !item.folder);
        if (batch) {
          createLines.call(this, batch, true);
          this.scanNetdisc();
          return;
        }
      }
      const newBatch = {
        uid,
        lines: [],
        folderId, //网盘目标文件夹id，不是唯一！！！
        path, //网盘路径
        folder: type !== "file", //该批次是文件还是文件夹上传
        done: false,
        token,
        emptyFolderPaths, //空文件夹路径
      };
      //文件夹上传，每次都算一个新的批次
      createLines.call(this);
      //将这批次数据放进总队列
      this.addBatch(newBatch);
      //生成这批次数据
      function createLines(batch = newBatch, target = false) {
        for (let i = 0; i < fileList.length; i++) {
          let id = target ? batch.lines.length + uid + i + 1 : uid + i + 1; //唯一标识
          let suffix = fileList[i].name.split(".").pop(); //后缀
          fileList[i].path = `${this.ossFolder}/${id}.${suffix}`; //文件上传后在oss的相对路径
          const line = {
            id,
            pid: uid,
            oss: null,
            options: {
              mime: "text/plain",
              parallel: 1,
              partSize: this.partSize(fileList[i].size),
              timeout: 1000 * 60 * 5,
            },
            abortCheckPoint: null,
            file: fileList[i],
            progress: 0,
            allowAction: true, //防止暴力点击
            status: "inline",
            parent: batch,
            speed: 0,
            forbidScan: false, //关闭线程后是否禁止自动开启其它线程（优先上传功能需要的参数）
            reUpload: 0, //上传异常后重新断点上传的次数
            remainderTime: 0, //剩余时间
            transmitStartTime: 0, //每次开始传输的时刻
            transmitPauseTime: 0, //每次暂停的传输时刻
            transmitTotalTime: 0, //总耗时
          };
          batch.lines.push(line);
        }
      }
    },
    partSize(size) {
      let point1 = 1024 * 1024 * 100; //100mb
      let point2 = 1024 * 1024 * 1024; //1gb
      if (size <= point1) {
        return 1024 * 1024; //1mb一片
      } else if (size <= point2) {
        return 1024 * 1024 * 5; //5mb一片
      } else {
        return 1024 * 1024 * 8; //5mb一片
      }
    },
    getSTSToken() {
      return this.$http
        .get("/ndRecord/getStsToken")
        .then((res) => {
          let token = {
            region: "oss-cn-hangzhou",
            accessKeyId: res.credentials.accessKeyId,
            accessKeySecret: res.credentials.accessKeySecret,
            stsToken: res.credentials.securityToken,
            bucket: res.bucket,
          };
          return Promise.resolve(token);
        })
        .catch((error) => {
          return Promise.reject();
        });
    },
    print() {
      console.log(this.netdisc);
    },
    open(failedData) {
      this.failedData = lodash.cloneDeep(failedData);
      this.failedData.uid = new Date().getTime(); //uid在发请求时已被后端记录，重新生成下
      this.failedTitle = moment().format("YYYY年MM月DD日HH时mm分") + "，";
      this.$refs.dialog.open();
    },
    saveDataInBrowser() {
      try {
        let data = JSON.parse(localStorage.getItem("netdiscFailedHistory")) || [];
        data.push({
          data: this.failedData,
          title: this.failedTitle,
          time: new Date().getTime(),
        });
        localStorage.setItem("netdiscFailedHistory", JSON.stringify(data));
        this.$message.success("保存成功，待服务器恢复后请尽快前往网盘处理");
        this.failedTitle = "";
        this.failedData = null;
        this.$refs.dialog.close();
      } catch (error) {
        this.$message.error("保存到浏览器失败");
      }
    },
    netdiscUploadHandler({ detail }) {
      console.log("监听到了,选择了文件");
      this.upload(detail);
      this.visible = true;
    },
  },
  computed: {
    remainderTimeOfBatch() {
      return ({ lines }) => {
        if (lines.some((line) => line.status === "pause" || line.status === "inline")) {
          return "--";
        }
        return this.countTimeUnit(Math.max(...lines.map((line) => line.remainderTime)));
      };
    },
    countTimeUnit() {
      //n为秒
      return (n) => {
        n = Number(n).toFixed(0);
        let min = Math.floor(n / 60);
        let seconds = Math.floor(n - min * 60);
        return `${min}分${seconds}秒`;
      };
    },
    countSpeedUnit() {
      //n为 nkb/s
      return (n) => {
        if (n < 1000) {
          return `${Number(n).toFixed(2)}KB/秒`;
        } else {
          return `${Number(n / 1024).toFixed(2)}MB/秒`;
        }
      };
    },
    //当前上传线程数是否满负荷运行
    fullLoaded() {
      let lineNum = 0;
      this.netdisc.forEach(({ lines }) => {
        lines.forEach(({ status }) => {
          status === "padding" && lineNum++;
        });
      });
      return lineNum === this.maxUploadLines;
    },
    //任意状态的线程集合
    allUploadLines() {
      let res = [];
      this.netdisc.forEach(({ lines }) => {
        res.push(...lines);
      });
      return res;
    },
    countNumberByStatus() {
      return (status) => {
        let num = 0;
        this.allUploadLines.forEach((line) => {
          line.status === status && num++;
        });
        return num;
      };
    },
    countTotalSpeed() {
      if (this.allUploadLines.length) {
        return this.allUploadLines.map((i) => i.speed).reduce((prev, next) => prev + next);
      }
      return 0;
    },
    //排队的线程队列中的第一个进程
    firstUploadLine() {
      return this.allUploadLines.find(({ status }) => status === "inline");
    },
    lineStatus() {
      let data = {
        inline: "排队中",
        transmitSuccess: "传输成功,等待任务中其它文件上传",
        success: "上传成功",
        failed: "上传失败",
        cancel: "已取消上传",
        pause: "已暂停",
        padding: "传输中",
        savefailed: "保存失败",
      };
      return (status) => data[status];
    },
    countSize() {
      return (size, progress) => {
        if (progress == 0) {
          return "0kb/秒";
        }
        let a, b, unit;
        if (size < 1024 * 1024) {
          a = (size * progress) / 1024;
          b = size / 1024;
          unit = "KB";
        } else if (size < 1024 * 1024 * 1024) {
          a = (size * progress) / (1024 * 1024);
          b = size / (1024 * 1024);
          unit = "MB";
        } else {
          a = (size * progress) / (1024 * 1024 * 1024);
          b = size / (1024 * 1024 * 1024);
          unit = "GB";
        }
        return `${Number(a).toFixed(2)}${unit}/${Number(b).toFixed(2)}${unit}`;
      };
    },
    countIcon() {
      return (filename) => {
        let suffix = "." + filename.split(".").pop().toLowerCase();
        let target = this.fileMap.find((item) => {
          return item.suffix.includes(suffix);
        });
        if (target) {
          return target.icon;
        } else {
          return "#icon-color-qita1";
        }
      };
    },
  },
  created() {
    window.addEventListener("netdiscUpload", this.netdiscUploadHandler);
  },
  beforeDestroy() {
    window.removeEventListener("netdiscUpload", this.netdiscUploadHandler);
  },
  components: {
    GSVG,
  },
};
</script>
<style lang="scss" scoped>
.flex-row-between {
  display: flex;
  justify-content: space-between;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
}
.netdisc-uploader {
  box-shadow: 0px 0px 6px 0px rgba(2, 28, 56, 0.15);
  border-radius: 10px 10px 0px 0px;
  height: 80vh;
  overflow: hidden;
  width: 800px;
  padding: 10px;
  padding-top: 0px;
  background: #f2f3f5;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2000;
  &.small {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 0;
    overflow: unset;
  }
  .small-box {
    cursor: pointer;
    user-select: none;
    text-align: center;
    margin-top: 6px;
    position: relative;
    &:hover i {
      display: inline;
    }
    i {
      cursor: pointer;
      position: absolute;
      top: -10px;
      right: 3px;
      background: #488af8;
      font-size: 12px;
      padding: 3px;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      display: none;
    }
    span {
      font-size: 12px;
      line-height: 58px;
    }
  }
  .hide {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    right: 5px;
    z-index: 2;
    cursor: pointer;
    &:hover span {
      background: #488af8 !important;
    }
    span.min {
      display: block;
      height: 4px;
      border-radius: 3px;
      background: #979797;
      width: 20px;
      margin: 13px 5px;
    }
  }
  .tip {
    padding: 12px 0;
    span {
      margin-left: 20px;
    }
    .tab-view {
      color: #488af8;
      cursor: pointer;
    }
  }
  ::v-deep .el-tabs__header {
    padding: 0 10px;
    margin: 0 -10px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
  }
  ::v-deep .el-tabs__content {
    display: none;
  }
  .uploader-wrapper {
    height: calc(80vh - 100px);
    overflow: auto;
  }
  .batch-wrapper {
    padding: 13px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
    .title {
      font-size: 14px;
      font-weight: bold;
      line-height: 15px;
      width: 440px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tool {
      width: 240px;
      color: #999;
      i {
        font-size: 20px;
        cursor: pointer;
      }
      .btn {
        color: #488af8;
        cursor: pointer;
        margin-right: 15px;
      }
      .reamainder {
        width: 125px;
        text-align: right;
      }
    }
    li {
      padding: 10px;
      background: #f7f7f9;
      position: relative;
      margin-top: 10px;
      border-radius: 4px;
      .file-info {
        width: 707px;
        margin-left: 10px;
        p.filename {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: bold;
          color: #111111;
          font-size: 14px;
          margin-bottom: 2px;
        }
        p.path {
          width: 520px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 500;
          color: #111111;
          line-height: 15px;
        }
        p.action {
          width: 90px;
          font-size: 12px;
          font-weight: 500;
          color: #488af8;
          cursor: pointer;
        }
      }
      span.not-allowed {
        cursor: not-allowed;
      }
      .progress-info {
        color: #999;
        & > div {
          width: 33%;
        }
        .time {
          text-align: right;
          span {
            .speed {
              padding-right: 10px;
            }
            span {
              display: inline-block;
              box-sizing: border-box;
              width: 50%;
            }
          }
        }
      }
      .process-line {
        width: 100%;
        position: relative;
        margin: 10px 0;
        height: 10px;
        div {
          border-radius: 5px;
          position: absolute;
          height: 10px;
          width: 100%;
        }
        .down {
          background: #fff;
          z-index: 1;
        }
        .up {
          background: #488af8;
          z-index: 2;
          transition: width ease-out 0.4s;
          &.success {
            background: #21c0a6;
          }
          &.transmitSuccess {
            background: #69d6d3;
          }
          &.savefailed {
            background: #ffa000;
          }
          &.failed,
          &.cancel {
            background: #ff5957;
          }
        }
      }
    }
  }
}
</style>