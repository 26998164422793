export const purchase = {
  local: {
    api: "",
    ip: `http://${process.env.LOCAL_IP}:9007`,
  },
  dev: {
    api: "",
    ip: "http://cg-dev.hb.com",
  },
  test: {
    api: "",
    ip: "http://cg-test.hb.com",
  },
  stage: {
    api: "",
    ip: "http://stagecg.hbtesaas.com",
  },
  production: {
    api: "",
    ip: "https://purchase.hbtesaas.com",
  },
  authority: {
    api: "",
    ip: "http://authority-cg.hb.com",
  },
};
